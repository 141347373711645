import React from "react"
import { Link } from "gatsby-plugin-intl"
import "./css/info-pager.styl"

const InfoPager = ({ lang, prev, next }) => {
  return (
    <ul className="pager">
      <li>
        {prev.slug && (
          <Link to={`/info/${prev.slug}`}>
            {lang === "ja" ? "＜ 前" : "＜ Prev"}
          </Link>
        )}
      </li>

      <li>
        <Link to="/info">{lang === "ja" ? "一覧に戻る" : "Info list"}</Link>
      </li>

      <li>
        {next.slug && (
          <Link to={`/info/${next.slug}`}>
            {lang === "ja" ? "次 ＞" : "Next ＞"}
          </Link>
        )}
      </li>
    </ul>
  )
}

export default InfoPager
