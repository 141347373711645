import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "./css/template-info.styl"
import InfoPager from "../components/info-pager"

export const query = graphql`
  query($slug: String!) {
    allContentfulInformation(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        publishedDate(formatString: "YYYY/MM/DD")
        language
        body {
          json
        }
        ogpImage{
          file{
            url
          }
        }
      }
    }
  }
`

function Info(props) {
  const intl = useIntl()
  const lang = intl.locale
  const nodes = props.data.allContentfulInformation.nodes
  const { prev, next } = props.pageContext

  const node = nodes.filter(node => node.language === lang)
  const {
    title,
    publishedDate,
    body: { json },
  } = node[0]
  const url = node[0].ogpImage ? node[0].ogpImage.file.url : null

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const caption = node.data.target.fields.description["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return (
          <figure>
            <img alt={alt} src={url} />
            <figcaption>{caption}</figcaption>
          </figure>
        )
      },
    },
  }

  return (
    <Layout location={props.location}>
      <SEO
        location={props.location}
        lang={lang}
        title={title}
        keywords={[`Info`, `お知らせ`]}
        description={title}
        ogpImageUrl={url || null} />
      <section className="information_post component">
        <h2>{title}</h2>
        <p className="date">{publishedDate}</p>
        <article>{documentToReactComponents(json, options)}</article>
      </section>
      <InfoPager lang={lang} prev={prev} next={next} />
    </Layout>
  )
}

export default Info
